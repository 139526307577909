const Background = () => {
    return (
        <div className="Background">
            <section id="main-title">
                <figure className="image-container">
                    <img className="Index-page-image" src="https://cdn.lizard.ninja/site-images/hasselblad-background.jpg" data-image-dimensions="2000x1333" alt="Hassblad-Background.jpg"/>
                </figure>
            </section>
        </div>
    )
}

export default Background;