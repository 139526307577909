const Shop = () => {
    return (
        <section>
            <div className="Shop">
                <p>Thanks for your interest - This is Coming Soon</p>
            </div>
        </section>
    )
}

export default Shop;