import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { images, CustomImage } from "./images";

const slides = images.map(({ original }) => ({
  src: original,
}));


const Theo = () => {
  const [index, setIndex] = useState(-1);

  const handleClick = (index: number, item: CustomImage) => setIndex(index);

  return (
    <div className="Body">
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={true}
      />
      <Lightbox
        plugins={[Zoom, Thumbnails]}
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
    </div>
  );
}

export default Theo