import { Link } from 'react-router-dom';
import logo from '../logo125.png';

const Nav = () => {
  return (
      <section id="Navigation">
        <span className="App-logo"><Link to="/" className="App-link"><img src={logo} alt="Lizard Ninja Logo" width="125" height="125" /></Link></span>
        <div className="Nav">
          <span className="NoBullets">
            <span className="NoBullets"><Link className="App-link" to='/shop'>Shop</Link></span>
            <span className="NoBullets"><Link className="App-link" to="/about">About</Link></span>
            <span className="Theo"><Link className="App-link-theo" to='/theo'>Theo</Link></span>
          </span>
        </div>
      </section>
  )
}

export default Nav;