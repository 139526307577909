const About = () => {
    return (
        <section className="About">
        <span className="About">
            <p>I use a mix of film and digital equipment to create my images. You can find me on Instagram at <a className="App-link" href="https://instagram.com/_LizardNinja">_LizardNinja</a> and email me at <a className="App-link" href='m&#97;&#105;l&#116;o&#58;%73ephi%40l&#105;%7Aard&#46;&#110;&#105;nj%61'>sephi&#64;li&#122;&#97;rd&#46;ninja</a>.</p>
        </span>
    </section>
    )
}

export default About;