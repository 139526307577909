const Home = () => {
    return (
        <section className="Home">
            <div className="Headliner">
              <p>Welcome to Lizard Ninja Media</p>
              <p>A mixed media photographer based in the North West of the UK.</p>
            </div>
        </section>
    )
}

export default Home;