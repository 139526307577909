import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Theo from './Theo';
import Shop from './Shop';
import About from './About';
import NoPage from './NoPage';
import Lorem from './Lorem';

const Main = () => {
return (         
    <Routes>
    <Route path='/' element={<Home/>} />
    <Route path='/about' element={<About/>} />
    <Route path='/theo' element={<Theo/>} />
    <Route path='/shop' element={<Shop/>} />
    <Route path='/lorem' element={<Lorem />} />
    <Route path='*' element={<NoPage/>} />
  </Routes>
);
}
export default Main;