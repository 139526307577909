import './App.css';
import Main from './pages/Main';
import Nav from "./pages/Nav";
import Background from './pages/Background';

export default function App() {
  return (
    <div className="body">
      <Background />
      <Nav />
      <Main />
  </div>
  );
}
